import React from "react"
import { Link } from "gatsby"
import { RiArrowRightSLine } from "react-icons/ri"

import SEO from "../components/seo"
import Layout from "../components/layout"

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"

// Demo styles, see 'Styles' section below for some notes on use.
// import "react-accessible-accordion/dist/fancy-example.css"

const Fragenkatalog = () => (
  <Layout className="page">
    <SEO title="BELMOT Oldtimerversicherung – Überblick und Leistungen" />
    <div className="grid-container grid-container--start">
      <h1>Fragenkatalog – FAQ</h1>

      <div className="accordion">
        <Accordion allowZeroExpanded>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Was versteht man unter dem Begriff Marktwert?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Rund 75% der deutschen Oldtimerversicherungen legen als
                Versicherungssumme den sogenannten Marktwert zugrunde. Bei
                seltener gehandelten Fahrzeugmodellen und bei Fahrzeugen, die
                schwerpunktmäßig gewerblich vertrieben werden fließen auch die
                Händlergewinnspanne, die internationalen Auktionsergebnisse
                (ohne Mehrwertsteuer) sowie die internationale Marktsituation
                mit in den Marktwert ein.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Was ist der Wiederbeschaffungswert?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Im Gegensatz zum Marktwert geht der Wiederbeschaffungswert von
                einer „kurzfristigen Wiederbeschaffung“ aus und ist deutlich
                höher. Er wird definiert als der Betrag, der tatsächlich für den
                Kauf eines gleichartigen und gleichwertigen Ersatzfahrzeugs auf-
                gewendet werden muss. Somit berücksichtigt er den Fahrzeug- kauf
                bei einem Händler.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Was versteht man unter Wiederaufbauwert?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Der Wiederaufbauwert berücksichtigt die Tatsache, dass die
                Kosten für ein zu restaurierendes Fahrzeug weitaus höher sind
                als der durchschnittliche Marktwert. Es fließen die
                Restaurierungs- sowie die Anschaffungskosten eines
                restaurierungsfähigen Basisfahrzeugs mit ein. Es handelt sich um
                einen hypothetischen Wert für ein Fahrzeug in Originalzustand.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Was ist eine Selbsteinschätzung bei der Oldtimerversicherung?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Das ist der von Ihnen persönlich definierte Wert Ihres
                Fahrzeugs. Bis zu einem Fahrzeugwert von 12.500 Euro akzeptiert
                BELMOT eine Selbsteinschätzung. Fotografie und ausgefülltes
                Formular genügen. Der von Ihnen festgelegte Wert gilt dann als
                Versicherungssumme. Bitte fügen Sie einen aktuellen TÜV-Bericht
                und – sofern vorhanden – Rechnungen über Restaurierungsmaßnahmen
                bei. Auch ein lückenlos gepflegtes Scheckheft gilt als Nachweis.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Was ist ein Kurzgutachten?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Das Kurzgutachten ist eine Zustandseinstufung nach grober,
                äußerlicher Inaugenscheinnahme, um den Wert für die
                Versicherungseinstufung zu bestimmen. Es wird weder eine
                Probefahrt durchgeführt noch werden technische Einzelheiten
                überprüft. Kosten: 150 Euro plus Gutachterkosten. Für ein
                Fahrzeug mit einem Versicherungswert über 100.000 Euro empfehlen
                wir ein ausführliches Wertgutachten.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Was ist ein ausführliches Gutachten?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Das ausführliche Gutachten, auch „großes Wertgutachten“ genannt,
                geht detailliert auf das zu bewertende Fahrzeug ein und
                berücksichtigt die gesamte Modellgeschichte und die
                Fahrzeugtechnik. Wertbeeinflussende Faktoren, wie z. B.
                Rennhistorie oder prominenter Vorbesitzer, fließen ebenfalls in
                die Wertermittlung mit ein. Die Kosten für ein ausführliches
                Wertgutachten beginnen bei 300 Euro. Für sehr seltene Fahrzeuge
                mit großem Recherche- bedarf ist mit Kosten ab 800 Euro zu
                rechnen.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Was ist eine restaurierungsbegleitendes Wertgutachten?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Vom Umfang her identisch mit einem ausführlichen Wertgutachten –
                jedoch setzt die Bewertung hier bereits in der Phase der
                Restauration an. Eine individuelle Besprechung mit dem Gutachter
                im Vorfeld ist empfehlenswert. Im Idealfall erfolgt die
                Begutachtung nach Abschluss der einzelnen Teilprozesse der
                Restauration, wie Instandsetzung von Karosserie, Technik und
                Lack. Eine „haut- nahe“ Qualitätsprüfung der durchgeführten
                Arbeiten am zerlegten Fahrzeug ist somit gewährleistet. Die
                Kosten für ein solches Gutachten liegen bei mindestens 800 Euro.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Welches Gutachten macht Sinn?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Setzen Sie auch bei der Bewertung Ihres Oldtimers auf ein
                „großes Wertgutachten“ von einem sachverständigen Gutachter mit
                einer fundierten Bewertung des Zustands und der Substanz des
                Fahrzeugs. Dies ist nach Restaurierungen oder umfangreichen
                Reparaturen, vor allem nach einem Zubehöreinbau, wichtig. Darauf
                aufbau- end kann jederzeit durch ein Kurzgutachten der Wert
                aktualisiert werden. Sinnvoll ist auch die regelmäßige Prüfung
                der Wertentwicklung vergleichbarer Modelle.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Wann macht ein rotes Wechselkennzeichen Sinn?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Das Wechselkennzeichen für mehrere Fahrzeuge gilt ausschließlich
                für die Teilnahme an Oldtimer-Veranstaltungen, An- und Abfahrten
                hierzu sowie Probe-, Prüfungs- und Überführungsfahr- ten und
                Fahrten zum Zwecke der Wartung oder Reparatur. Es gelten
                dieselben Bedingungen wie beim H-Kennzeichen.
                <br />
                <br />
                <h5>Kfz-Steuer</h5>
                Pro Kennzeichen wird (unabhängig von Hubraum und Leistung) eine
                Pauschalsteuer von zurzeit 191,73 Euro fällig.
                <br />
                <br />
                <h5>An- und Abmeldung</h5>
                Die Wechselkennzeichen sind unter Vorlage von Antragsdokumenten
                bei der Zulassungsbehörde erhältlich. Generell ist der
                Zulassungsbehörde ein polizeiliches Führungszeugnis vor- zulegen
                und ein Auszug aus dem Punkteregister in Flensburg ist
                erforderlich.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Wann macht ein Saison-Kennzeichen Sinn?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Für alle, die ihr Fahrzeug nur für begrenzte Monate des Jahres
                bewegen möchten! Es gelten die Bedingungen der regulären
                Zulassung mit dem schwarzen Kennzeichen. Das Alter des Oldtimers
                spielt keine Rolle. Für die Zulassung brauchen Sie lediglich
                eine aktuelle Versicherungsbestätigung. Ein Saisonzeitraum gilt
                immer vom ersten Tag 0.00 Uhr bis zum letzten Tag 24.00 Uhr. Ein
                Saisonkennzeichen 03–10 ist also vom 1. März bis zum 31. Oktober
                eines jeden Jahres gültig. Ihr Fahrzeug darf außer- halb des
                Saisonzeitraumes nicht am öffentlichen Straßenverkehr
                teilnehmen. Nicht zulässig ist auch das Abstellen des Fahrzeuges
                auf öffentlichen Straßen oder teilöffentlichem Gelände (z.B.
                frei zugänglicher Parkplatz).
                <br />
                <br />
                <h5>Kfz-Steuer</h5>
                Die Kfz-Steuer wird anteilig nur für den Saisonzeitraum erhoben.
                <br />
                <br />
                <h5>An- und Abmeldung</h5>
                Die An- und Abmeldung entfällt: Sie müssen nicht jedes Jahr aufs
                Neue zur Zulassungsstelle gehen, die Zulassung tritt automatisch
                wieder in Kraft. Es fallen auch nur einmal – wie beim schwarzen
                Kennzeichen – die Gebühren für die Zulassung an.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Wann macht ein schwarzes Kennzeichen Sinn?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Eher etwas für Youngtimer: Die Standardzulassung ist für einen
                Oldtimer nicht empfehlenswert, da sie das Fahrzeug nach Hub-
                raum besteuert und dadurch oftmals teurer ist als das
                H-Kennzeichen. Auch die Fahrt in Umweltzonen ist oftmals nicht
                möglich.
                <br />
                <br />
                <h5>Kfz-Steuer</h5>
                Für den Besitzer eines Oldtimers bietet das schwarze Kennzeichen
                nur unter bestimmten Voraussetzungen Vorteile. Durch die
                konventionelle Hubraumbesteuerung ist es in der Regel teurer als
                ein historisches Kennzeichen. Folgende Steuersätze sind seit
                April 2007 gültig (gilt für Fahrzeuge, die schlechter eingestuft
                sind als Euro 1):
                <br />
                Benziner: 25,36 Euro je angefangene 100 cm3 Hubraum Diesel:
                38,78 Euro je angefangene 100 cm3 Hubraum. Zum Vergleich: Ein
                Oldtimer mit H-Kennzeichen kostet Sie zurzeit nur 191,73 Euro p.
                a., unabhängig vom Hubraum.
                <br />
                <br />
                <h5>An- und Abmeldung</h5>
                Achten Sie – je nach Landkreis – bei einer vorübergehenden
                Stilllegung, z. B. zwecks Restaurierung oder Winterruhe, auf die
                Reservierung Ihres Kennzeichens bis zum Zeitpunkt der
                Wiederzulassung. Die Kfz-Steuer wird taggenau abgerechnet.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Wann macht ein H-Kennzeichen Sinn?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                H wie historisch: Ihr Oldtimer muss hierfür mindestens 30 Jahre
                alt sein und Sie müssen per Gutachten nachweisen, dass er der
                „Pflege des Kfz-technischen Kulturgutes“ zugeordnet werden kann.
                Das bedeutet, dass er in gut erhaltenem Originalzustand sein
                muss. Übrigens: Fahrzeuge mit H-Kennzeichen genießen freie Fahrt
                in Feinstaubzonen.
                <br />
                <br />
                <h5>Kfz-Steuer</h5>
                Der Steuersatz wird pauschal erhoben: 191,73 Euro p. a. für
                Kraftfahrzeuge. 46 Euro p. a. für Krafträder.
                <br />
                <br />
                <h5>An- und Abmeldung</h5>
                Wie bei einem regulären schwarzen Kennzeichen kann die Dauer der
                Zulassung frei gewählt werden. Die Kfz-Steuer wird taggenau
                abgerechnet. Viele Oldtimerfahrer verzichten aufgrund des
                günstigen Steuersatzes auf die Abmeldung über den Winter.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Wie wähle ich den richtigen Abstellort?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                <h5>Tiefgarage</h5>
                Nicht empfehlenswert für hochwertige Klassiker. Besonders
                Parkhäuser und öffentliche Tiefgaragenparkplätze sind als
                dauerhafter Stellplatz ungeeignet – auch im Hinblick auf den
                Werterhalt Ihres Fahrzeugs.
                <br />
                <br />
                <h5>Carport</h5>
                Einfachste Möglichkeit, ein Fahrzeug trocken unter zustellen.
                Der Carport sollte sich auf einem um zäunten Grundstück befinden
                und nicht von außen einsehbar sein. Für hochwertige Klassiker
                nicht zu empfehlen.
                <br />
                <br />
                <h5>Halle</h5>
                Ein beliebter Ort, um Sammlungen abzustellen. Die Halle sollte
                in einem guten baulichen Zustand und durch besondere Maßnahmen
                gegen Einbruch gesichert sein. Damit die Fahrzeuge vor Rost und
                Schimmel bewahrt werden, empfiehlt sich eine Be- und
                Entlüftungsanlage einzubauen.
                <br />
                <br />
                <h5>Einzelgarage</h5>
                Einzel- und Doppelgaragen sind optimale Abstellorte. Aber auch
                hier sind Einbruchmöglichkeiten gegeben. Eine
                Einbruchmeldeanlage schafft Abhilfe. Aufgrund der
                Luftzirkulation sollte die Anschaffung einer Be- und
                Entlüftungsanlage in Erwägung gezogen werden. Besonders zu
                empfehlen bei hochwertigen Oldtimern.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Wie wähle ich den richtigen Abstellort?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                <h5>Mechanisch sichern</h5>
                Sie können Gaunern das Leben erschweren, indem Sie Ihren
                Oldtimer mechanisch sichern. Vor allem unterwegs bei einer
                Ausfahrt oder einem Kurztrip verhindern Sicherungen den
                Gelegenheitsdiebstahl.
                <br />
                <br />
                In Frage kommen
                <ul>
                  <li>eine Lenkrad- und/oder Pedalkralle,</li>
                  <li>eine mechanische oder elektronische Wegfahrsperre,</li>
                  <li>eine Parkkralle.</li>
                </ul>
                <br />
                <br />
                <h5>Elektronisch sichern</h5>
                Mit dem Einbau eines Ortungsmoduls erhöhen Sie die Chance auf
                das Wiederauffinden Ihres Fahrzeuges nach einem Diebstahl
                deutlich.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>

      <div className="home__anfragebanner home__anfragebanner--small">
        <h3>Unverbindliches Angebot sichern</h3>
        <Link to="/formular" className="button button--secondary">
          Jetzt kostenfrei anfragen
          <span class="icon -right">
            <RiArrowRightSLine />
          </span>
        </Link>
      </div>
    </div>
  </Layout>
)

export default Fragenkatalog
